import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  constructor(@Inject(PLATFORM_ID) private readonly platformID: Object) {}
  public set accessToken(accessToken: string | null) {
    if (isPlatformBrowser(this.platformID))
      localStorage.setItem('accessToken', accessToken!);
  }

  public get accessToken(): string | null {
    if (isPlatformBrowser(this.platformID))
      return localStorage.getItem('accessToken');
    return null;
  }

  public set refreshToken(refreshToken: string | null) {
    if (isPlatformBrowser(this.platformID))
      localStorage.setItem('refreshToken', refreshToken!);
  }

  public get refreshToken(): string | null {
    if (isPlatformBrowser(this.platformID))
      return localStorage.getItem('refreshToken');

    return null;
  }

  public set language(language: string | null) {
    if (isPlatformBrowser(this.platformID))
      localStorage.setItem('language', language!);
  }

  public get language(): string | null {
    if (isPlatformBrowser(this.platformID))
      return localStorage.getItem('language');
    return null;
  }

  removeItem(key: string) {
    if (isPlatformBrowser(this.platformID)) localStorage.removeItem(key);
  }
}
